@import "../../config.scss";

#customers {
	display: block;

	@include md {
		display: flex;
		padding: 0;
	}

	.about {
		@include md {
			width: 150%;
			margin: auto;
			padding: 7% 2% 6% 15%;
		}

		@include lg {
			padding: 7% 2% 6% 25%;
		}

		img {
			display: block;
			margin: auto;
			padding-left: 30px;
		}

		.text {
			width: 75%;
			margin: auto;
		}

		h2, h3 {
			color: palette(mono, base);
			text-align: right;
		}

		h3 {
			padding: 0;
		}

		p {
			color: palette(mono, medium);

			&:last-of-type {
				text-align: right;
				padding: 0;
			}
		}
	}

	.testimonials {
		background-color: palette(blue, medium);
		padding: 8% 0 8%;
		color: palette(mono, white);
		text-align: center;

		@include md {
			width: 100%;
		}

		img {
			padding-bottom: 20px;
		}

		p {
			width: 75%;
			margin: auto;
		}

		h4 {
			font-style: italic;

			&:after, &:before {
				display: inline-block;
				content: '';
				width: 10%;
				min-height: 2px;
				background-color: white;
				text-align: center;
				margin-bottom: 3px;
			}

			&:after {
				margin-left: 10px;
			}

			&:before {
				margin-right: 10px;
			}
		}

		.testimonial {
			display: none;

			&.visible {
				display: block;
			}
		}
	}
}
