@import "../../config.scss";

#services {
	background-color: palette(blue, medium);
	color: palette(mono, white);
	text-align: center;
	padding: 120px 15% 155px 15%;
	margin: auto;

	h1 {
		text-transform: uppercase;
		padding-bottom: 90px;
	}

	.services {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin: -32.5px;

		.service {
			width: 280px;
			margin: 32.5px;

			img {
				width: 205px;
				padding-bottom: 35px;
			}

			.title {
				background-color: palette(mono, medium);
				text-transform: uppercase;
				padding: 10px;
			}

			.details {
				border: palette(mono, white) solid 2px;
				border-top: none;
				margin-top: 10px;

				p {
					position: relative;
					margin: 0;
					padding: 20px 0;
					font-size: 15px;

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						width: 50%;
						left: 25%;
						border-bottom: palette(mono, white) solid 1.5px;
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
}
