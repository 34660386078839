@import "../../config.scss";

.fixed-header {
	.full-nav {
		position: fixed;
		top: 0;
    width: 100%;
	}
}

.full-nav {
	z-index: 100;
	height: $nav-height;
	width: 100%;
	position: absolute;
	justify-content: space-around;
	bottom: 0;
	background-color: palette(mono, dark);
	color: palette(mono, white);
	display: none;
	align-items: center;
	border-top: palette(mono, base) dotted;

	@include md {
		display: flex;
	}

	@include lg {
		a {
			&:first-child {
				margin-left: 10vw;
			}

			&:last-child {
				margin-right: 10vw;
			}
		}
	}

	a {
		@include font(open-sans, light);
		font-size: 16px;
		text-decoration: none;
		color: palette(mono, light);

		&:hover,
		&:active,
		&:visited {
			color: palette(mono, light);
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.mobile-nav {
	z-index: 100;
	background-color: palette(mono, dark);
	color: palette(mono, white);
	position: fixed;
	top: 0;
	width: 100%;

	@include md {
		display: none;
	}

	.bar {
		border-bottom: palette(mono, base) dotted;
		padding: 5px;
	}

	.links {
		display: none;
    flex-direction: column;

		&.visible {
			display: flex;
		}

		a {
			font-size: 30px;
			color: palette(mono, base);
			padding: 5px 20px;
			text-decoration: none;
		}
	}
}
