@import "./config.scss";

#splash {
	padding: 0;

	#splash-background {
		background-color: palette(mono, dark);
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;

		@include md {
			height: calc(100vh - #{$nav-height});
		}
	}

	#background-image {
		position: absolute;

		img {
			width: auto;
			margin-left: 20%;
			margin-top: -22%;
			max-height: 100vh;
			opacity: 0.2;
		}
	}
}

.content {
	@include md {
		padding-top: $nav-height;
	}
}
