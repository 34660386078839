@import "../../config.scss";

#contact {
	background-color: palette(mono, medium);
	padding: 20px 2% 60px;

	@include lg {
		display: flex;
    flex-flow: row wrap;
    padding: 10vh 6%;
	}

	h1 {
		color: palette(mono, white);
		text-align: center;
		margin-bottom: 10vh;

		@include lg {
			width: 100%;
		}
	}

	#bird-divider {
		display: none;
		position: relative;
		width: 45%;
		text-align: center;
		border-right: white solid 2px;

		> img {
			position: absolute;
			height: auto;
			margin: auto;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
		}

		@include lg {
			display: block;
		}
	}

	.contact-area {
		@include lg {
			width: 50%;
			padding-left: 4%;
		}
	}

	form {
		display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
		margin: 15px;

		textarea, input {
			margin-bottom: 20px;
		}

		textarea {
			width: calc(100% - 30px);
			height: 110px;
		}

		input {
			width: 75%;

			@include lg {
				width: 40%;
			}
		}
	}

	.methods {
		display: flex;
    flex-flow: row wrap;
		justify-content: space-around;

		.method {
			text-align: center;
			width: 50%;
			color: palette(mono, white);
			text-decoration: none;

			@include sm {
				width: 25%;
			}
		}
	}
}
