@import "../../config.scss";

#footer {
	background-color: palette(mono, darkest);
	color: palette(mono, white);
	display: flex;
	flex-direction: column;
	padding: 20px 0 15px;

	@include md {
		flex-direction: row;
		justify-content: space-around;
		padding-left: 5vw;
		padding-right: 5vw;
	}

	@include lg {
		padding-left: 10vw;
		padding-right: 10vw;
	}

	@include xl {
		padding-left: 10vw;
		padding-right: 10vw;
	}


	.logo {
		max-width: 200px;
		margin: auto;

		@include md {
			margin: auto 3%;
		}
	}

	.social {
		display: flex;
		justify-content: space-evenly;
		margin: 10px 0;

		@include md {
			margin: auto 0;
			width: 20%;
		}

		img {
			width: 50px;
		}
	}

	.links {
		display: flex;
    flex-flow: row;
    flex-wrap: wrap;
		color: palette(mono, base);

		@include md {
			flex-direction: column-reverse;
		}

		a {
			text-align: center;
			width: 50%;

			@include md {
				width: 100%;
				padding-bottom: 10px;
				text-align: left;
			}

			&:link, &:visited, &:active {
				color: palette(mono, base);
				text-decoration-color: palette(mono, white);
			}

			&:hover {
				color: palette(mono, white);
			}
		}

		p {
			order: 1;
			text-align: center;
			width: 100%;
			font-size: 16px;

			@include md {
				padding: 0;
			}
		}
	}

	.language {
		color: palette(mono, base);

		p {
			padding: 0;
		}

		select {
			@include font(merriweather, light);
			color: palette(mono, white);
			font-size: 18px;
			line-height: 2rem;
			background-color: palette(mono, slate);
			border: none;
			border-bottom: palette(mono, white) solid 2px;
			appearance: none;
		}
	}
}
