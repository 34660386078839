@import "../../config.scss";

#home {
	padding: 10vh 0;

	.welcome {
		display: flex;
		align-items: center;
		flex-direction: column;

		h1 {
			color: palette(mono, base);
		}

		p {
			width: 85vw;
			text-align: center;
			font-size: 15px;

			@include md {
				width: 55vw;
			}
		}

		img {
			padding: 20px 0;
		}
	}

	.recent-projects {
		h1 {
			color: palette(mono, base);
			text-align: center;
		}

		.slider-wrapper {
			width: 100%;

			@include lg {
				width: 65%;
			}
		}

		.control-dots {
			.dot {
				border-color: palette(mono, base);
				background-color: palette(mono, whjite);
				border: solid 3px;
				width: 12px;
				height: 12px;

				&.selected {
					color: palette(mono, base);
				}
			}
		}

		.slide {
			padding: 50px;
			background-color: white;
		}
	}
}
