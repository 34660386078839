@import "./config.scss";
@import "reset-css";

body {
	width: 100%;
	height: 100%;
	margin: 0;
}

body {
	margin: 0;
}

h1, h2, h3, h4, h5 {
	@include font(montserrat, medium);
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 20px;
}

p {
	@include font(merriweather, light);
	font-size: 18px;
	line-height: 2rem;
}

textarea, input {
	background-color: palette(mono, gray);
	color: palette(mono, white);
	border: none;
	padding: 15px;

	&::placeholder {
		color: palette(mono, lighter);
	}
}

button {
	background-color: palette(mono, medium);
	color: palette(mono, white);
	border-color: palette(mono, white);
	border: 3px solid;
	padding: 15px 100px;
}
