@import url('https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap');

$font-stack: (
	merriweather: (
		light: (
			family: (Merriweather, serif),
			weight: 300,
			style: normal
		),
		bold: (
			family: (Merriweather, serif),
			weight: 700,
			style: normal
		)
	),
	montserrat: (
		regular: (
			family: (Montserrat, sans-serif),
			weight: 400,
			style: normal
		),
		medium: (
			family: (Montserrat, sans-serif),
			weight: 500,
			style: normal
		),
		semi-bold: (
			family: (Montserrat, sans-serif),
			weight: 600,
			style: normal
		)
	),
	open-sans: (
		light: (
			family: (Open Sans, sans-serif),
			weight: 300,
			style: normal
		)
	)
);

@mixin font($group, $variant: regular, $properties: family weight style) {
  @debug map-get(map-get($font-stack, $group), $variant);
	$font-properties: map-get(map-get($font-stack, $group), $variant);

  @if $font-properties {
    @each $property, $values in $font-properties {
      @if contains($properties, $property) {
        font-#{$property}: map-get($font-properties, $property);
      }
    }
  }
}

$palettes: (
	mono: (
		black: #000,
		darkest: #141414,
		dark: #313138,
		slate: #434549,
		medium: #2c2d2d,
		gray: #595B5A,
		base: #aaaaaa,
		light: #c2c2c4,
		lighter: #BDBDBE,
		white: #fff
	),
	blue: (
		medium: #49a5c5,
		dark: #4d899a,
		darker: #5A727C
	)
);

@function palette($palette, $tone: "base") {
	@return map-get(map-get($palettes, $palette), $tone);
}

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 801px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
			@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
			@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
			@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
			@content;
	}
}


$nav-height: 75px;
