@import "../../config.scss";

#why {
	display: flex;
	padding: 0;
	background-color: palette(mono, medium);

	.text {
		width: 150%;
		padding-top: 95px;
		padding-right: 45px;
		padding-bottom: 155px;

		> * {
			@include lg {
				max-width: 500px;
				margin-left: auto;
			}
		}
	}

	h2 {
		color: palette(mono, base);
		padding: 45px 0 30px 0;
	}

	p {
		color: palette(mono, light);
	}

	img {
		width: 100%;
		height: auto;
		display: none;

		@include lg {
			display: block;
		}
	}
}
