@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700|Montserrat:400,500,600|Open+Sans:300&display=swap);
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,menu,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,main,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section{display:block}*[hidden]{display:none}body{line-height:1}menu,ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}body{width:100%;height:100%;margin:0}body{margin:0}h1,h2,h3,h4,h5{font-family:Montserrat,sans-serif;font-weight:500;font-style:normal}h1{font-size:40px}h2{font-size:30px}h3{font-size:20px}p{font-family:Merriweather,serif;font-weight:300;font-style:normal;font-size:18px;line-height:2rem}textarea,input{background-color:#595B5A;color:#fff;border:none;padding:15px}textarea::-webkit-input-placeholder, input::-webkit-input-placeholder{color:#BDBDBE}textarea::-moz-placeholder, input::-moz-placeholder{color:#BDBDBE}textarea:-ms-input-placeholder, input:-ms-input-placeholder{color:#BDBDBE}textarea::-ms-input-placeholder, input::-ms-input-placeholder{color:#BDBDBE}textarea::placeholder,input::placeholder{color:#BDBDBE}button{background-color:#2c2d2d;color:#fff;border-color:#fff;border:3px solid;padding:15px 100px}

#splash{padding:0}#splash #splash-background{background-color:#313138;height:100vh;display:flex;align-items:center;justify-content:center}@media (min-width: 801px){#splash #splash-background{height:calc(100vh - 75px)}}#splash #background-image{position:absolute}#splash #background-image img{width:auto;margin-left:20%;margin-top:-22%;max-height:100vh;opacity:0.2}@media (min-width: 801px){.content{padding-top:75px}}

.fixed-header .full-nav{position:fixed;top:0;width:100%}.full-nav{z-index:100;height:75px;width:100%;position:absolute;justify-content:space-around;bottom:0;background-color:#313138;color:#fff;display:none;align-items:center;border-top:#aaa dotted}@media (min-width: 801px){.full-nav{display:flex}}@media (min-width: 992px){.full-nav a:first-child{margin-left:10vw}.full-nav a:last-child{margin-right:10vw}}.full-nav a{font-family:Open Sans,sans-serif;font-weight:300;font-style:normal;font-size:16px;text-decoration:none;color:#c2c2c4}.full-nav a:hover,.full-nav a:active,.full-nav a:visited{color:#c2c2c4}.full-nav a:hover{cursor:pointer}.mobile-nav{z-index:100;background-color:#313138;color:#fff;position:fixed;top:0;width:100%}@media (min-width: 801px){.mobile-nav{display:none}}.mobile-nav .bar{border-bottom:#aaa dotted;padding:5px}.mobile-nav .links{display:none;flex-direction:column}.mobile-nav .links.visible{display:flex}.mobile-nav .links a{font-size:30px;color:#aaa;padding:5px 20px;text-decoration:none}

#home{padding:10vh 0}#home .welcome{display:flex;align-items:center;flex-direction:column}#home .welcome h1{color:#aaa}#home .welcome p{width:85vw;text-align:center;font-size:15px}@media (min-width: 801px){#home .welcome p{width:55vw}}#home .welcome img{padding:20px 0}#home .recent-projects h1{color:#aaa;text-align:center}#home .recent-projects .slider-wrapper{width:100%}@media (min-width: 992px){#home .recent-projects .slider-wrapper{width:65%}}#home .recent-projects .control-dots .dot{border-color:#aaa;border:solid 3px;width:12px;height:12px}#home .recent-projects .control-dots .dot.selected{color:#aaa}#home .recent-projects .slide{padding:50px;background-color:white}

#services{background-color:#49a5c5;color:#fff;text-align:center;padding:120px 15% 155px 15%;margin:auto}#services h1{text-transform:uppercase;padding-bottom:90px}#services .services{display:flex;flex-flow:row wrap;justify-content:center;margin:-32.5px}#services .services .service{width:280px;margin:32.5px}#services .services .service img{width:205px;padding-bottom:35px}#services .services .service .title{background-color:#2c2d2d;text-transform:uppercase;padding:10px}#services .services .service .details{border:#fff solid 2px;border-top:none;margin-top:10px}#services .services .service .details p{position:relative;margin:0;padding:20px 0;font-size:15px}#services .services .service .details p:after{content:"";position:absolute;bottom:0;width:50%;left:25%;border-bottom:#fff solid 1.5px}#services .services .service .details p:last-child:after{display:none}

#why{display:flex;padding:0;background-color:#2c2d2d}#why .text{width:150%;padding-top:95px;padding-right:45px;padding-bottom:155px}@media (min-width: 992px){#why .text>*{max-width:500px;margin-left:auto}}#why h2{color:#aaa;padding:45px 0 30px 0}#why p{color:#c2c2c4}#why img{width:100%;height:auto;display:none}@media (min-width: 992px){#why img{display:block}}

#customers{display:block}@media (min-width: 801px){#customers{display:flex;padding:0}}@media (min-width: 801px){#customers .about{width:150%;margin:auto;padding:7% 2% 6% 15%}}@media (min-width: 992px){#customers .about{padding:7% 2% 6% 25%}}#customers .about img{display:block;margin:auto;padding-left:30px}#customers .about .text{width:75%;margin:auto}#customers .about h2,#customers .about h3{color:#aaa;text-align:right}#customers .about h3{padding:0}#customers .about p{color:#2c2d2d}#customers .about p:last-of-type{text-align:right;padding:0}#customers .testimonials{background-color:#49a5c5;padding:8% 0 8%;color:#fff;text-align:center}@media (min-width: 801px){#customers .testimonials{width:100%}}#customers .testimonials img{padding-bottom:20px}#customers .testimonials p{width:75%;margin:auto}#customers .testimonials h4{font-style:italic}#customers .testimonials h4:after,#customers .testimonials h4:before{display:inline-block;content:'';width:10%;min-height:2px;background-color:white;text-align:center;margin-bottom:3px}#customers .testimonials h4:after{margin-left:10px}#customers .testimonials h4:before{margin-right:10px}#customers .testimonials .testimonial{display:none}#customers .testimonials .testimonial.visible{display:block}

#contact{background-color:#2c2d2d;padding:20px 2% 60px}@media (min-width: 992px){#contact{display:flex;flex-flow:row wrap;padding:10vh 6%}}#contact h1{color:#fff;text-align:center;margin-bottom:10vh}@media (min-width: 992px){#contact h1{width:100%}}#contact #bird-divider{display:none;position:relative;width:45%;text-align:center;border-right:white solid 2px}#contact #bird-divider>img{position:absolute;height:auto;margin:auto;top:0;bottom:0;right:0;left:0}@media (min-width: 992px){#contact #bird-divider{display:block}}@media (min-width: 992px){#contact .contact-area{width:50%;padding-left:4%}}#contact form{display:flex;flex-flow:row wrap;justify-content:space-between;margin:15px}#contact form textarea,#contact form input{margin-bottom:20px}#contact form textarea{width:calc(100% - 30px);height:110px}#contact form input{width:75%}@media (min-width: 992px){#contact form input{width:40%}}#contact .methods{display:flex;flex-flow:row wrap;justify-content:space-around}#contact .methods .method{text-align:center;width:50%;color:#fff;text-decoration:none}@media (min-width: 576px){#contact .methods .method{width:25%}}

#footer{background-color:#141414;color:#fff;display:flex;flex-direction:column;padding:20px 0 15px}@media (min-width: 801px){#footer{flex-direction:row;justify-content:space-around;padding-left:5vw;padding-right:5vw}}@media (min-width: 992px){#footer{padding-left:10vw;padding-right:10vw}}@media (min-width: 1200px){#footer{padding-left:10vw;padding-right:10vw}}#footer .logo{max-width:200px;margin:auto}@media (min-width: 801px){#footer .logo{margin:auto 3%}}#footer .social{display:flex;justify-content:space-evenly;margin:10px 0}@media (min-width: 801px){#footer .social{margin:auto 0;width:20%}}#footer .social img{width:50px}#footer .links{display:flex;flex-flow:row;flex-wrap:wrap;color:#aaa}@media (min-width: 801px){#footer .links{flex-direction:column-reverse}}#footer .links a{text-align:center;width:50%}@media (min-width: 801px){#footer .links a{width:100%;padding-bottom:10px;text-align:left}}#footer .links a:link,#footer .links a:visited,#footer .links a:active{color:#aaa;-webkit-text-decoration-color:#fff;text-decoration-color:#fff}#footer .links a:hover{color:#fff}#footer .links p{order:1;text-align:center;width:100%;font-size:16px}@media (min-width: 801px){#footer .links p{padding:0}}#footer .language{color:#aaa}#footer .language p{padding:0}#footer .language select{font-family:Merriweather,serif;font-weight:300;font-style:normal;color:#fff;font-size:18px;line-height:2rem;background-color:#434549;border:none;border-bottom:#fff solid 2px;-webkit-appearance:none;-moz-appearance:none;appearance:none}

